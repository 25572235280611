@import '../../../config/variables';

.modalRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.modalLabel {
  color: $secondaryTextColor;
  font-size: $labelSize;
  width: 50%
}

.modalValue {
  font-size: $labelSize;
  width: 50%;
  text-align: right;
  color: $primaryTextColor;
}

.tabEntry {
  height: 30px;
  cursor: pointer;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
}

.cardRow {
  width: 95%;
  border-radius: 10px;
  margin-top: 10px;
  min-height: 60px;
  border-width: 0.5px;
  border-style: solid;
  padding: 0px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer
}

.actionButton {
  margin-top: 10px;
  width: 250px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  border: 1px solid gray;
  cursor: pointer;
}
