@import '../../../config/variables';

.metricBox {
  height: 100px;
  width: 18%;
  background-color: $theme;
  border-radius: $contentBorderRadius;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.tableHeader {
  font-weight: 600;
  font-size: $tableHeaderSize;
  height: 40px;
  line-height: 2.8;
  padding-left: 10px;
  text-align: left;
  background-color: $theme;
  margin-right: 10px;
  color: $tertiaryTextColor;
}

.tableRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 50px;
}

.tableRowEntry {
  padding-left: 10px;
  text-align: left;
  font-size: $entryTextSize;
}

.inactive {
  color: $secondaryTextColor;
}