@import '../../../config/variables';

.chartWrapper {
  border-radius: 10px;
  width: 90%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.tooltipWrapper {
  background-color: white;
  padding: 20px;
  border: 0px;
}

.dateRangeDisplayContainer {
  display: flex;
  flex-direction: row;
  height: 50px;
  width: 55%;
  border-radius: 5px;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
