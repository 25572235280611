@import "../../../config/variables";

.formWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.formRow {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-bottom: 20px;
}

.vehicleTypeWrapper {
  height: 200px;
  width: 100%;
  border-radius: 10px;
  padding: 4px;
  margin-top: 10px;
}


.imageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.selectedTypes {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  padding: 4px 10px;
  border-radius: 8px;
  position: relative;
  height: 24px;
  background-color: $themeLight;
  color: $tertiaryTextColor;
}

.unselectedTypes {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  padding: 4px 10px;
  border-radius: 8px;
  cursor: pointer;
  height: 24px;
  border: 1px solid $themeLight;
}

.tabEntry {
  height: 30px;
  cursor: pointer;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
}
