@import "../../../config/variables";

.listContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  height: 85vh;
  overflow-y: scroll;
}

.listRow {
  border-bottom: 1px solid #acacac;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.tableHeader {
  font-size: 16px;
  font-weight: 600;
  padding-left: 4px;
}

.tableEntry {
  font-size: 14px;
  padding-left: 4px;
}

.modalLabel {
  font-size: 14px;
  color: $secondaryTextColor;
  width: 140px;
}

.modalValue {
  font-size: 14px;
  color: $primaryTextColor;
}
