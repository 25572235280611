@import '../../../config/variables';

.salesPplContainer {
  display: flex;
  flex-direction: column;
  width: 300px;
  border: 1px solid gray;
  border-radius: 10px;
  height: 200px;
  padding: 0 10px 10px 10px;
  overflow-y: scroll;
  background-color: $theme;
}

.salesRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 40px;
}

.tableHeader {
  background-color: $theme;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  height: 240px;
  background-color: #acacac;
}

.imageContainer2 {
  display: flex;
  flex-direction: column;
  width: 60%;
  border-radius: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  min-height: 240px;
}
