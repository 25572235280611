@import "../../../config/variables";

.container {
  padding-bottom: 100px;
}

.closeBtnWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center
}

.closeBtn {
  border-radius: 50%;
  border: 1px solid white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.imageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.imageWrapper {
  width: 200px;
  height: 140px;
  border: 1px solid #303030;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
}

.vehiclesListContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  height: 75vh;
  overflow-y: scroll;
}

.vehicleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $tableLineColor;
  margin-bottom: 10px;
  width: 100%;
  flex: 1;
}

.maintenanceRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $tableLineColor;
  margin-bottom: 10px;
  width: 100%;
  height: 40px;
}

.entryRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}



.tableHeader {
  margin-left: 10px;
  font-size: $tableHeaderSize;
  font-weight: 600;
}

.tableEntry {
  padding-left: 10px;
}

.tableHeaderWrapper {
  background-color: $theme;
}

.tableEntryWrapper {
  font-size: $entryTextSize;
}
