@import "../config/variables";

.container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.newReservationModalContainer {
  height: 80vh;
}
