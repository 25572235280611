@import '../../../config/variables';

.rowContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  height: 40vh;
}

.upcomingWrapper {
  width: 100%;
  height: 80%;
  border-radius: 10px;
  overflow-y: scroll;
}

.reservationRow {
  width: 95%;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  cursor: pointer;
}

.reservationRowHeader {
  font-weight: bold;
  color: $primaryTextColor;
}

.reservationRowEntry {
  font-weight: 100;
  color: $secondaryTextColor;
}

.chartWrapper {
  border-radius: 10px;
  width: 90%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.tooltipWrapper {
  background-color: white;
  width: 100px;
  padding: 10px;
}

