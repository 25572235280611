@import '../config/variables';

.container {
  height: 100vh;
  background-color: #ffffff;
  width: 100%;
  overflow-x: hidden;
  padding: 0px 20px;
}


.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  cursor: pointer;
}

.darkText {
  color: $theme;
}

.grayText {
  color: $secondaryTextColor;
}

.entryLabel {
  font-weight: 100;
  font-size: $labelSize;
}

.lightText {
  color: $tertiaryTextColor;
}

.boxShadow {
  box-shadow: 0px 4px 25px 0px #0000001F;
  border-color: #E7E7E7;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}
