@import "../../../config/variables";


.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.logo {
  margin-top: 100px;
  margin-bottom: 20px;
}

.formContainer {
  margin-top: 50px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 40vh;
  justify-content: space-evenly;
}
