@import '../../../config/variables';

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-top: 10px;
}

.fieldName {
  color: $secondaryTextColor;
  width: 30%;
}

.fieldValue {
  color: $primaryTextColor;
  width: 70%;
}