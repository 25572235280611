@import '../../../config/variables';

.listContainer {
  height: 80vh;
  width: 100%;
  margin-top: 30px;
}

.tableHeader {
  font-weight: 1000;
  font-size: $tableHeaderSize;
  height: 40px;
  line-height: 2.8;
  padding-left: 10px;
  text-align: left;
}

.tableRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 50px;
  justify-content: space-between;
}

.tabEntry {
  height: 30px;
  cursor: pointer;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
}

.tableRowEntry {
  padding-left: 10px;
  text-align: left;
  font-size: $entryTextSize;
}
