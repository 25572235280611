@import '../../../config/variables.scss';

.tabEntry {
    height: 30px;
    cursor: pointer;
    width: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 5px;
    align-items: center;
}