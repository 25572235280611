@import '../../../config/variables';


.imageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}
