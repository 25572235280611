$theme: #23395D;
$buttonTheme: #5481CB;
$themeLight: #5481CB;
$primaryTextColor: #23395D;
$secondaryTextColor: #A0A3BD;
$tertiaryTextColor: #ffffff;
$darkTextColor: #23395D;
$contentBackgroundColor: #FFFFFF;
$tableHeaderSize: 14px;
$tableEntrySize: 12px;
$entryTextSize: 15px;
$tableLineColor: #C7C7C7;
$contentBorderRadius: 10px;
$labelSize: 16px;
