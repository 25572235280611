@import "../../../config/variables";

.navBarContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: $theme;
}

.linkRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;
  height: 40px;
}
