@import 'react-big-calendar/lib/sass/styles';
$border-color: red !important;
$calendar-border: red !important;
$cell-border: red !important;
$event-bg: white !default;
$event-border: white !default;
$event-outline: white !default;

body {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  font-family: Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
