@import '../../../config/variables';

.tabEntry {
  height: 30px;
  cursor: pointer;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 5px;
  align-items: center;
}

.paymentsContainer {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  margin-top: 10px;
}


.tableRow {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 30px;
}

.tableHeader {
  font-weight: 600;
  font-size: $tableHeaderSize;
  height: 40px;
  line-height: 2.8;
  padding-left: 10px;
  text-align: left;
  background-color: $theme;
  color: $tertiaryTextColor;
}

.tableRowEntry {
  padding-left: 10px;
  text-align: left;
  font-size: $entryTextSize;
  color: $theme;
}

.modalRow {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.modalLabel {
  color: $secondaryTextColor;
  font-size: $labelSize;
  width: 50%
}

.modalValue {
  font-size: $labelSize;
  width: 50%;
  text-align: right;
  color: $primaryTextColor;
}
